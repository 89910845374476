@import url('https://fonts.googleapis.com/css?family=Montserrat'); 
/* letter-spacing has a centering bug if the font is not imported */
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  background-color: #fafafa;
  text-align: left;
  height: 100vh;
}

html {
  scroll-behavior: smooth;
}

.button {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  border-radius: 24px;
  line-height: 42px;
  border-color: #000;
  border-style: solid;
  border-width: 1px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
  transition: all 0.4s ease;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.button:hover {
  color: #fff;
  background-color: #000;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
}

.navbar .navcenter {
  width: 100%;
  max-width: 1088px;
  display: flex;
  align-items: center;
  margin: auto;
}

.title {
  display: block;
  text-decoration: none;
  color: inherit;
  margin-left: 12px;
  font-size: 60px;
  font-weight: 700;
  line-height: 64px;
  color: #000;
}

.navbuttons {
  margin-left: auto;
}

.navbuttons .button {
  margin: 12px;
}

.nspace {
  height: 68px;
}

.anchor {
  text-decoration: none;
  color: #888;
  text-transform: uppercase;
  line-height: 2;
  font-size: 12px;
}

.profile {
  display: flex;
  max-width: 1048px;
  height: 100svh;
  margin: -68px auto auto auto;
  padding: 0 16px 0 16px;
}

.profilec {
  display: flex;
  margin: auto;
}

.profile img {
  align-self: center;
  width: 300px;
  height: 300px;
  margin: 0;
}

.profile .desc {
  align-self: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 24px 24px 24px 0;
  justify-content: left;
}

.profile .button {
  margin: 24px 0 0 0;
  position: relative;
  left: -4px;
}

.profile .heading {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 16px;
}

.profile .text {
  font-size: 30px;
  margin-bottom: 16px;
}

.profile .down {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 48px;
}

.profile .down a {
  margin: auto;
  text-decoration: none;
  color: inherit;
}

.profile .down img {
  width: 48px;
  height: 48px;
  object-fit: cover;
}

@media (max-width: 1400px) {
  .anchor {
    visibility: hidden;
  }
}

.row {
  display: flex;
  max-width: 1128px;
  margin: auto;
}

@media (max-width: 1127px) {
  .row {
    display: block;
    width: 100%;
  }
}

.prev {
  width: 500px;
  padding: 32px;
  margin: 0 auto auto auto;
}

.prev a {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.prev img {
  width: 500px;
  height: 320px;
  transition: all 0.4s ease;
  border-radius: 20px;
}

.prev img:hover {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}

.prev .heading {
  font-family: 'Fraunces', serif;
  font-size: 30px;
  font-weight: 700;
  margin: 16px 8px;
  text-align: left;
}

.prev .text {
  margin: 0 8px;
}

.footer {
  width: 100%;
  max-width: 80vw;
  padding: 32px 0 16px 0;
  display: flex;
  flex-direction: row;
  margin: auto;
  line-height: 64px;
  align-items: center;
}

.footer .button {
  margin: 12px;
}

.footerl {
  flex-grow: 1;
  font-size: 15px;
  font-weight: 700;
  margin-left: 12px;
}

.project {
  width: 880px;
  margin: auto;
}

.columns {
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
}

.columnsd {
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
}

.columnsm {
  display: none;
  margin: 0 auto;
  box-sizing: border-box;
}

.pimg {
  object-fit: scale-down;
  margin: auto;
  display: flex;
  box-sizing: border-box;
}

.ptext {
  padding: 0 32px;
  box-sizing: border-box;
}

.mobile {
  display: none;
}

.mobileblock {
  display: none;
}

b {
  font-weight:700;
}

h1 {
  font-family: 'Fraunces', serif;
  font-size: 48px;
  font-weight: 800;  
  letter-spacing: 0em;
  margin: 32px 0 0 0;
  padding: 0 32px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

h2 {
  margin-top: 64px;
  text-align: left;
}

h3 {
  margin-bottom: 0;
  text-align: left;
}

ul {
  margin: 0;
  padding-inline-start: 22px;
}

.quote {
  display: flex;
}

.quote img {
  width: 64px;
  height: 64px;
  display: flex;
  margin: auto 16px;
}

.quotetext {
  display: flex;
  margin: auto;
  font-style: italic;
}

@media (orientation: portrait) {
  .prev .heading {
    font-size: 36px;
  }

  body {
    font-size: 20px;
    text-size-adjust: none;
  }

  .profile {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .profilec {
    flex-direction: column;
  }

  .profile img {
    display: flex;
    margin: 0 auto 0 auto;
  }

  .profile .desc {
    margin: 32px;
  }

  .profile .desc .text {
    margin: 0 32px;
  }

  .project {
    width: 100%;
  }

  .columns {
    display: block;
    width: 100% !important;
  }

  .columnsd {
    display: none;
  }

  .columnsm {
    display: flex;
  }

  .columns .ptext {
    width: 100% !important;
  }

  .columns .quote {
    width: 100% !important;
    padding: 0 32px;
    box-sizing: border-box;
  }

  .mobile {
    display: flex;
  }

  .mobileblock {
    display: block;
  }

  .desktop {
    display: none;
  }

  .bigbutton {
    font-size: 22.5px;
    border-radius: 36px;
    line-height: 63px;
    margin-top: 36px !important;
    }
}

.border {
  border-color: #000;
  border-style: solid;
  border-width: 1px;
}